import { axiosInstance } from "../Axios";
import { downloadFileReusable } from "../utils/Utils";
import { downloadReceipts } from "./LoloReceiptActions";
import { notify } from "./NotiActions";

import { startLoading, stopLoading } from "./UIActions";

export const TOGGLE_SELF_TRANSPORT = "TOGGLE_SELF_TRANSPORT";
// CONTAINER SEARCH
export const CONTAINER_SEARCH = "CONTAINER_SEARCH";
export const GET_CONTAINER_BY_DATE = "GET_CONTAINER_BY_DATE";
export const GET_DROPDOWN_VALUES = "GET_DROPDOWN_VALUES";
export const CLEAR_DROPDOWN_VALUES = "CLEAR_DROPDOWN_VALUES";
export const SELECT_SEAL_NUMBER = "SELECT_SEAL_NUMBER";
// LOlO SEARCH
export const GET_LOLO_PAYMENT_SEARCH_RESULT = "GET_LOLO_PAYMENT_SEARCH_RESULT";
export const SET_SELECTED_LOLO_PAYMENT_SEARCH =
  "SET_SELECTED_LOLO_PAYMENT_SEARCH";

// SELF TRANSPORT SEARCH
export const GET_SELF_TRANSPORT_SEARCH_RESULT =
  "GET_SELF_TRANSPORT_SEARCH_RESULT";
export const SET_SELECTED_SELF_TRANSPORT_SEARCH =
  "SET_SELECTED_SELF_TRANSPORT_SEARCH";

export const containerSearchDispatch =
  (body, setDropdown) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const bodyData = {
      container_no: body.container_no,
      location: location,
      site: site,
    };
    axiosInstance
      .post("depot/container_search/", bodyData)
      .then((res) => {
        setDropdown(true);
        if (res.data.container_no)
          dispatch({ type: CONTAINER_SEARCH, payload: res.data });
        else {
          dispatch({ type: CONTAINER_SEARCH, payload: res.data.errorMsg });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  export const cacheCleanDataDispatch =
  ( setLoader,notify) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const bodyData = {
      location: location,
      site: site,
    };
    if(location===""||site===""){
      notify("Please select location and site first.",{variant:"warning"})
      return
    }
    setLoader(true);
    axiosInstance
      .post("dms_cache/manage_cache_keys/", bodyData)
      .then((res) => {
        if (res.data.successMsg){
          setLoader(false);
          notify("Analytics Cache Data cleaned successfully",{variant:"success"})
          window.location.reload();
        }  
        else {
          setLoader(false);
          notify("Analytics Cache Data not cleaned ,try again ",{variant:"error"})
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  };

export const downloadGateLicenseImage =(pk,type,notify)=>async (dispatch)=>{
  axiosInstance
  .post(`depot/download_driver_details/${pk}/`, {process:type},{responseType:"arraybuffer"})
  .then((res) => {
    downloadFileReusable(res.data,"driver_licesne.jpg","application/.jpeg")
  })
  .catch((err) => {
    console.log(err);
  });
}

export const getContainerByDateDispatch =
  (body, setDropdown) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    body["location"] = location;
    body["site"] = site;
    axiosInstance
      .post("depot/update_gate_in/", body)
      .then((res) => {
        dispatch({
          type:  "EDIT_DRIVER_LICENSE_IMAGE",
          payload: "",
        });
        dispatch({
          type:  "SET_DRIVER_IMAGE",
          payload: "",
        });
        let {gate_in_data,...data} =res.data
        
        dispatch({
          type: GET_CONTAINER_BY_DATE,
          payload: {...data,gate_in_data:{image_url:"",...gate_in_data}},
        });
        dispatch({ type: "SET_GIH_PK", payload: res.data.gih_pk });
        setDropdown(false);
        if (res.data.self_transportation_data === "") {
          dispatch({ type: "SET_ENABLE_TRANSPORTATION" });
        } else {
          console.log("Here");
          dispatch({ type: "SET_DISABLE_TRANSPORTATION" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const dropDownContainerAction = () => async (dispatch, getState) => {
  const location = await getState().user.location;
  const site = await getState().user.site;

  axiosInstance
    .post("surveyor/import_surveyor_containers/", {
      location: location,
      site: site,
    })
    .then((res) => {
      dispatch({ type: "GATE_IN_CONTAINER_LIST", payload: res.data });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const ContainerSurveyGetAction =
  (pk, notify) => async (dispatch, getState) => {
    dispatch({ type: "START_LOADING" });
    axiosInstance
      .get(`surveyor/import_surveyor_data_in_gate_in/${pk}/`)
      .then((res) => {
        notify("Container Found", { variant: "success" });
        dispatch({ type: "EDIT_CONTAINER_SURVEY_DATA", payload: res.data });
        dispatch({ type: "SET_GATE_IN_CONTAINER_DETAILS", payload: res.data });
        dispatch({ type: "STOP_LOADING" });
      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        console.error(err);
      });
  };

export const ContainerSurveyGetNONDEPOTAction =
  (pk, notify) =>
  async (dispatch, getState) => {

    dispatch({ type: "START_LOADING" });
    axiosInstance
      .get(`surveyor/import_surveyor_data_in_gate_in/${pk}/`)
      .then((res) => {
        notify("Container Found", { variant: "success" });
        dispatch({
          type: "GET_MNR_CONTAINER_BY_DATE",
          payload: res.data,
        });
        dispatch({ type: "STOP_LOADING" });
      })
      .catch((err) => {
        dispatch({ type: "STOP_LOADING" });
        console.error(err);
      });
  };

export const dropDownDispatch =
  (array, alert,dashboard) => async (dispatch, getState) => {
    dispatch({ type: "START_LOADING" });
    await dispatch({ type: "CLEAR_DROPDOWN_VALUES" });
    const location = await getState().user.location;
    const site = await getState().user.site;
    const stateCode = await getState().user.state_code;

    const dataArray = array !== undefined ? array : [];
    axiosInstance
      .post("depot/inform_dropdown/", {
        location: location,
        site: site,
        state_code: stateCode,
        get_list:dashboard? [...dataArray,"client_ref_codes"]:dataArray,
        
      })
      .then((res) => {
        console.log("Inform Dependency", res);
        dispatch({ type: GET_DROPDOWN_VALUES, payload: res.data });
        dispatch({ type: "STOP_LOADING" });
      })
      .catch((err) => {
        console.error(err);
        if (!err.response)
          alert("Please check your internet connection and reload the page", {
            variant: "info",
          });
        dispatch({ type: "STOP_LOADING" });
      });
  };

export const setCustomerNameVal = (value, setValue) => async () => {
  setValue(value);
};

export const saveGateInInfo = (body, alert) => (dispatch) => {
  dispatch(startLoading());
  axiosInstance
    .post("depot/gate_in/", body)
    .then((res) => {
      if (res.data.successMsg) {
        alert(res.data.successMsg, {
          variant: "success",
        });
        dispatch({ type: "SET_GIH_PK", payload: res.data.gih_pk });
        dispatch({ type: "SET_IS_REQUIRED", payload: false });
        if (
          body.self_transportation_data === "" ||
          body.self_transportation_data === undefined
        ) {
          dispatch({ type: "SET_ENABLE_TRANSPORTATION" });
        } else {
          console.log("Here");
          dispatch({ type: "SET_DISABLE_TRANSPORTATION" });
        }
      } else if (res.data.errorMsg) {
        alert(res.data.errorMsg, {
          variant: "error",
        });
      }
      dispatch(stopLoading());
    })
    .catch((err) => {
      console.error(err.response);
      if (err.response.data.code === "token_not_valid") {
        alert("Session expired please login again", {
          variant: "info",
        });
      }
      dispatch(stopLoading());
    });
};

export const loloPaymentSearch = (body) => (dispatch) => {
  axiosInstance
    .post("depot/lolo_payment_search/", body)
    .then((res) => {
      dispatch({ type: GET_LOLO_PAYMENT_SEARCH_RESULT, payload: res.data });
    })
    .catch((err) => {
      console.error(err.response.data);
    });
};

export const selfTransportSearch = (body) => (dispatch) => {
  axiosInstance
    .post("depot/self_transportation_payment_search/", body)
    .then((res) => {
      dispatch({ type: GET_SELF_TRANSPORT_SEARCH_RESULT, payload: res.data });
    })
    .catch((err) => {
      console.error(err.response.data);
    });
};

export const updateGateInDetails =
  (body, alert) => async (dispatch, getState) => {
    dispatch(startLoading());
    const location = await getState().user.location;
    const site = await getState().user.site;
    body["location"] = location;
    body["site"] = site;
    axiosInstance
      .put("depot/update_gate_in/", body)
      .then((res) => {
        if (res.data.errorMsg) {
          alert(res.data.errorMsg, {
            variant: "error",
          });
        } else {
          if (
            body.self_transportation_data === "" ||
            body.self_transportation_data === undefined
          ) {
            dispatch({ type: "SET_ENABLE_TRANSPORTATION" });
          } else {
            console.log("Here");
            dispatch({ type: "SET_DISABLE_TRANSPORTATION" });
          }
          alert(res.data.successMsg, {
            variant: "success",
          });
        }
        dispatch(stopLoading());
      })
      .catch((err) => {
        if (err.response.data.code === "token_not_valid") {
          alert("Session expired please login again", {
            variant: "info",
          });
        }
        dispatch(stopLoading());
      });
  };

export const printGatePass = (gih_pk, alert) => async (dispatch, getState) => {
  const location = await getState().user.location;
  const site = await getState().user.site;
  return axiosInstance
    .post(
      `depot/gate_in_pass/${gih_pk}/`,
      {
        location: location,
        site: site,
      },
      { responseType: "arraybuffer" }
    )
    .then((res) => {
      if (res.data) {
        downloadReceipts(res.data, "Gate-Pass");
      }
    })
    .catch((err) => {
      if (err.response.data.code === "token_not_valid") {
        alert("Session expired please login again", {
          variant: "info",
        });
      } else {
        alert(err.response, { variant: "error" });
      }
    });
};

// DO CHALAAN
export const gateInDOUpload = (file, gih_pk, alert) => async () => {
  try {
    const res = await axiosInstance.post(`depot/in_do_upload/${gih_pk}/`, file);
    if (res.data.successMsg) {
      alert(res.data.successMsg, {
        variant: "success",
      });
    } else {
      alert(res.data.errorMsg, {
        variant: "error",
      });
    }
  } catch (err) {
    alert(err, {
      variant: "error",
    });
  }
};

export const gateInDODownload = (gih_pk) => async () => {
  try {
    const res = await axiosInstance.get(`depot/in_do_upload/${gih_pk}/`, {
      responseType: "arraybuffer",
    });
    let type = {};
    if (res.headers["content-type"] === "application/.pdf") {
      type = {
        type: "application/pdf",
      };
    } else {
      type = {
        type: "application/zip",
      };
    }
    downloadReceiptsDO(res.data, "DO_Chalaan", type);
  } catch (err) {
    console.log(err);
  }
};

export const containerValidatorDispatch =
  (body, gateInEditContainerNo, alert) => (dispatch) => {
    axiosInstance
      .post("depot/container_no_validation/", body)
      .then((res) => {
        console.log(res.data);
        if (
          res.data.errorMsg &&
          res.data.errorMsg !== "Container_no Not Valid"
        ) {
          alert(res.data.errorMsg, {
            variant: "error",
          });
        }
        if (
          res.data.successMsg ||
          res.data.errorMsg === "Container_no Not Valid"
        ) {
          if (gateInEditContainerNo) {
            dispatch({
              type: "EDIT_CONTAINER_NUMBER",
              payload: body.container_no,
            });
          } else {
            dispatch({
              type: "SET_CONTAINER_NUMBER",
              payload: body.container_no,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err, {
          variant: "error",
        });
      });
  };

export const downloadReceiptsDO = (byteString, FileName, DownloadType) => {
  let blob = new Blob([byteString], DownloadType);
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = FileName;
  link.click();
};
