export const monthArray =["January","February","March","April","May","June","July","August","September","October","November","December"]

Date.shortMonths = ["January","February","March","April","May","June","July","August","September","October","November","December"]

// Define a JavaScript function called short_months with parameter dt (date)
export  function short_months(dt)
{ 
  // Return the short month name corresponding to the month of the provided date
  return Date.shortMonths[dt.getMonth()]; 
}

export const downloadFileReusable = (arrayBuffer, FileName,type) => {
  let blob = new Blob([arrayBuffer], { type: type });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = FileName;
  link.click();
};