const initialState = {
  allSiteListing: [],
  siteDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SITES":
      return { ...state, allSiteListing: action.payload };
    case "GET_SINGLE_SITE_DETAIL": {
      return { ...state, siteDetails: action.payload };
    }
    case "CLEAN_SITE_MASTER":
      return { ...state, siteDetails: initialState.siteDetails };
    default:
      return { ...state };
  }
};
