
import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Badge,
  Popover,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Left from "@material-ui/icons/ChevronLeft";
import Logo from "../assets/images/snp-logo.jpeg";
import NotificationModal from "./NotificationModal";
import { useDispatch, useSelector } from "react-redux";
import { webNotificationGetMessageAction } from "../actions/WebSocketNotificationAction";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 2000,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  notifictionButton: {
    margin: "auto 85%",
    cursor: "pointer",
  },
  hide: {
    display: "none",
  },
  header: {
    fontWeight: 600,
    letterSpacing: 3,
    color: "#243545",
  },
  closeIcon: {
    float: "right",
  },
}));

const AppbarHeader = (props) => {
  const classes = useStyles();
  const { handleDrawerOpen, handleDrawerClose, open } = props;
  const { user } = useSelector((state) => state);
  const [socketCount, setSocketCount] = useState(0);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const socketRef = useRef(null);
  const domain = window.location.host;
  // const appLayerProtocol = window.location.protocol.split(":")[0];

  const openPop = Boolean(anchorEl);
  const idPop = openPop ? "simple-popover" : undefined;

  // Determine the WebSocket base URL based on the domain
  let wsBaseURL;
  // if (domain === "localhost:3000") {
  //   wsBaseURL = "wss://newstag-api.decomans.com/ws/notifications";
  // } else if (domain.includes("decomans.com")) {
  //   wsBaseURL = `wss://${domain.split(".")[0]}-api.decomans.com/ws/notifications`;
  // } else {
  //   wsBaseURL = "wss://api.decomans.com/ws/notifications";
  // }

  domain === "localhost:3000"
    ? wsBaseURL = "wss://newstag-api.decomans.com/ws/notifications"
    : (domain === "decomans.com" || domain === "www.decomans.com")
      ? wsBaseURL = "wss://api.decomans.com/ws/notifications"
      : wsBaseURL = `wss://${domain.split(".")[0]}-api.decomans.com/ws/notifications`;

  useEffect(() => {
    const newSocket = new WebSocket(
      `${wsBaseURL}/${user.location ? user.location : "all"}/${user.site ? user.site : "all"}/`
    );
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connected");
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setSocketCount(data.count);
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    newSocket.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (socketRef.current) {
        console.log("Closing WebSocket connection.");
        socketRef.current.close();
      }
    };
  }, [user.location, user.site]);




  const sendMessage = (data) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(data));
      dispatch(webNotificationGetMessageAction());
    }
  };

  const allReadMessage = (data) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(data));
    }
  };

  const openModal = (event) => {
    dispatch(webNotificationGetMessageAction());
    setAnchorEl(event.currentTarget);
  };

 

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ backgroundColor: "transparent" }}
    >
      <Toolbar style={{ backgroundColor: "#fff" }}>
        {user.role !== "Surveyor" && user.role !== "Repair" && (
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            {open ? <Left fontSize="large" /> : <MenuIcon />}
          </IconButton>
        )}

        <img src={Logo} alt="" height="30" width="fit-content" />
        <Typography variant="h5" className={classes.header}>
          SNP
        </Typography>
        {(user.role === "Surveyor" || user.role === "Repair") ? "" : <Badge
          badgeContent={socketCount}
          color="primary"
          aria-describedby={idPop}
          onClick={openModal}
          className={classes.notifictionButton}
        >
          <NotificationsActiveIcon color="action" />
        </Badge>}
        {(user.role === "Surveyor" || user.role === "Repair") && (
          <Button
            style={{ display: "flex", margin: "auto", marginRight: "4px" }}
            onClick={() => (window.location.href = "login")}
            startIcon={<LogoutIcon />}
            variant="text"
          >
            Logout
          </Button>
        )}
      </Toolbar>
      <Popover
        id={idPop}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: 3000, minWidth: "600px" }}
      >
        <NotificationModal sendMessage={sendMessage} allReadMessage={allReadMessage} />
      </Popover>
    </AppBar>
  );
};
export default AppbarHeader;