import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
// import 'bootstrap/dist/css/bootstrap.min.css';

const phone = window.innerWidth <= 350 || "orientation" in window;





ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={phone ? 1 : 3} dense={phone} preventDuplicate>
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
