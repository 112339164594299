export const USER_INFO = {
  PROCUREMENT_MODULE: "PROCUREMENT_MODULE",
};

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  location: localStorage.getItem("location")
    ? localStorage.getItem("location")
    : null,
  site: localStorage.getItem("site") ? localStorage.getItem("site") : null,
  type: localStorage.getItem("type") ? localStorage.getItem("type") : null,
  mnr_module: localStorage.getItem("mnr_module")
    ? localStorage.getItem("mnr_module") === "true"
      ? "True"
      : "False"
    : null,
  transportation_module: localStorage.getItem("transportation_module")
    ? localStorage.getItem("transportation_module") === "true"
      ? "True"
      : "False"
    : null,
  new_billing_module: localStorage.getItem("new_billing_module")
    ? localStorage.getItem("new_billing_module") === "true"
      ? "True"
      : "False"
    : null,
  loaded_yard_module: localStorage.getItem("loaded_yard_module")
    ? localStorage.getItem("loaded_yard_module") === "true"
      ? "True"
      : "False"
    : null,
  procurement_module: localStorage.getItem("procurement_module")
    ? localStorage.getItem("procurement_module") === "true"
      ? "True"
      : "False"
    : null,
  mnr_ftp_upload: localStorage.getItem("mnr_ftp_upload")
    ? localStorage.getItem("mnr_ftp_upload") === true
      ? true
      : false
    : null,
  automatic_mnr_status_change: localStorage.getItem(
    "automatic_mnr_status_change"
  )
    ? localStorage.getItem("automatic_mnr_status_change") === "true"
      ? "True"
      : "False"
    : null,
  role: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).role
    : null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload };
    case "SET_LOCATION":
      return { ...state, location: action.payload };
    case "SET_SITE":
      return { ...state, site: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_MNR_MODULE":
      return { ...state, mnr_module: action.payload };
    case "SET_TRANSPORTATION_MODULE":
      return { ...state, transportation_module: action.payload };
    case "SET_LOADED_EMPTY_YARD_MODULE":
      return { ...state, loaded_yard_module: action.payload };
    case USER_INFO.PROCUREMENT_MODULE:
      return { ...state, procurement_module: action.payload };
    case "SET_NEW_BILLING_MODULE":
      return { ...state, new_billing_module: action.payload };
    case "SET_AUTO_STATUS_CHANGE":
      return { ...state, automatic_mnr_status_change: action.payload };
    case "SET_MNR_FTP_UPLOAD":
      return { ...state, mnr_ftp_upload: action.payload };
    case "SET_ROLE":
      return { ...state, role: action.payload };
    default:
      return { ...state };
  }
};
